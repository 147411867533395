import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { isIE, isChrome, isEdge } from 'react-device-detect'
import Loading from '@skillsoft/ui/lib/Loading'
import Switch from '@skillsoft/ui/lib/Switch'
import persistence from '../../utils/persistence'
import { getCourseUrl, getLaunchUrl } from '../../common/launchlink/api'
import style from './Player.module.css'
import Icon from '@skillsoft/ui/lib/Icon'
import Info from '@skillsoft/ui/lib/icons/Info'
import ToolTip from '@skillsoft/ui/lib/Tooltip'
import { className } from 'postcss-selector-parser'
import FeedbackModal from '../../feedbackModal/containers/FeedbackModal'

class Player extends Component {
    constructor(props) {
        super(props)
        this.state = {
            courseUrl: null,
            loading: true,
            iFrameStyle: {
                height: 680,
            },
            iFrameFullScreenStyle: {
                height: 1080,
                width: "100%"
            },
            showA11yToggle: true
        }

        this.messageHandler = this.messageHandler.bind(this)
        this.onSwitchReady = this.onSwitchReady.bind(this)
    }

    onSwitchReady(e) {
        if (e)
            e.focus()
    }

    componentDidMount() {
        window.addEventListener('message', this.messageHandler)
        let launchParams = JSON.parse(persistence.get('launchParams'))
        launchParams = {
            ...launchParams,
            a11yEnabled: launchParams.a11yEnabled || false,
        }
        // Only show A11y toggle in IE11 and if a11y is supported
        // Need launchParams updated to determine if a11y is supported (i.e., does course use RIA?)
        // Will have to update this to support Chrome instead of IE11 in the future
        const showA11yToggle = (isIE || isChrome || isEdge)
        this.setState({
            showA11yToggle,
            launchParams,
        }, () => this.launch())

    }

    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler)
    }

    async launch() {
        const { launchCourse, orgId } = this.props
        const { launchParams } = this.state
        const courseUrl = await getLaunchUrl(launchParams)
        const whitelistUrl = await getCourseUrl(orgId, launchParams.courseId)

        launchCourse(launchParams, courseUrl)
        this.setState({
            courseUrl,
            loading: false,
            whitelistUrl,
        })
    }

    messageHandler(e) {
        // message structure for question and followup slides = { id: 'string', body }
        // message structure for message slide = { id: 'string', body: { clientHeight: 'string', message: 'string' } }

        // console.log(`Message from RIA ->`, e.data.id)
        switch (e.data.id) {
            case 'NASBA_SURVEY_Button_Click':
                this.NASBA_NPSButton_Click()
                break
            case 'NPS_Button_Click':
                this.NPSButton_Click()
                break
            case 'exitWindow':
                window.location.replace('/')
                break
            case 'scrollHeight':
                const message = JSON.parse(e.data.body)
                const { feedbackModalIsOpen } = this.props

                if (feedbackModalIsOpen) return

                if (message && message.message === 'messageSlide') {
                    const { messageSlideHeight } = this.state
                    if (!messageSlideHeight) this.setState({ messageSlideHeight: parseInt(message.clientHeight) })
                    if (messageSlideHeight) {
                        this.setState({
                            iFrameStyle: {
                                height: messageSlideHeight,
                            },
                        })
                    }
                    else {
                        this.setState({
                            iFrameStyle: {
                                height: parseInt(message.clientHeight),
                            },
                        })
                    }
                }
                else {
                    this.setState({
                        iFrameFullScreenStyle: {
                            height: parseInt(e.data.body),
                            width: "100%"
                        },
                    })
                }

                break
        }
    }

    toggleA11y() {
        const { launchParams } = this.state
        const { toggleA11y } = this.props
        const toggle = !launchParams.a11yEnabled
        toggleA11y(toggle)
        const newParams = {
            ...launchParams,
            a11yEnabled: toggle,
        }
        persistence.set('launchParams', JSON.stringify(newParams))
        this.setState({
            launchParams: newParams,
            loading: true,
        }, () => this.launch())
    }

    NASBA_NPSButton_Click() {
        const { launchParams } = this.state
        const { NASBAFeedback, orgId, studentId } = this.props
        NASBAFeedback(launchParams, orgId, studentId);
    }

    NPSButton_Click() {
        const { launchParams } = this.state
        const { giveFeedback, orgId, studentId } = this.props
        giveFeedback(launchParams, orgId, studentId);
    }

    renderContent() {
        if (this.state.loading)
            return <></>;

        const { strings } = this.props
        const { disablePercA11yToggle } = this.props
        const { courseUrl, iFrameStyle, iFrameFullScreenStyle, showA11yToggle, launchParams, whitelistUrl } = this.state
        const frameStyle = launchParams.hasVideo ? iFrameFullScreenStyle : iFrameStyle
        const autoplay = whitelistUrl ? `autoplay 'src' ${whitelistUrl.courseUrl}` : `autoplay`
        const frameAllow = autoplay

        return (
            <div className={launchParams.hasVideo ? style.playerWrapFullScreen : style.playerWrap}>
                {launchParams.player === "ria" && !disablePercA11yToggle && !launchParams.hasVideo && (
                    <div>
                        {showA11yToggle ? (
                            <div className={style.a11yToggle}>
                                <label htmlFor="a11yToggle">{strings.contentA11y}</label>
                                <Switch
                                    labelId="a11yToggle"
                                    switched={launchParams.a11yEnabled}
                                    showLabels={true}
                                    onClick={() => this.toggleA11y()}
                                    disabled={false}
                                    offLabel={strings.off}
                                    onLabel={strings.on}
                                    customLabels={true}
                                    id="a11yToggle"
                                    onSwitchReady={this.onSwitchReady}
                                />
                            </div>
                        ) : <div className={style.a11yToggle}><span className="a11yWarning">{strings.contentA11y}</span>
                            <a href="https://documentation.skillsoft.com/en_us/percipio/Content/B_Learner/Player_Compliance/plyr_web_accessesibility.htm" target="_blank">
                                <ToolTip
                                    className="playerTooltip"
                                    overlay={<span>{strings.a11yWarning}</span>}
                                    tooltipProps={{
                                        align: { offset: [-10, 0] },
                                        placement: 'left',
                                    }}
                                >
                                    <Icon className="card-info-icon" icon={Info} />
                                </ToolTip>
                            </a>
                        </div>}
                    </div>
                )}
                <iframe style={frameStyle} allowFullScreen frameBorder="0" allow={frameAllow} src={courseUrl} title={launchParams.course} />
                <FeedbackModal />
            </div>
        )
    }

    render() {
        return (
            <Loading loading={this.state.loading} withLogo>
                {this.renderContent()}
            </Loading>
        )
    }
}

export default Player
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { launchLinkShape } from '../shapes'
import persistence from '../../../utils/persistence'

class LaunchLink extends Component {
  static propTypes = PropTypes.shape(launchLinkShape).isRequired

  static defaultProps = {
    launchCourse: () => { },
    confirmCourse: () => { },
    children: null,
    launchable: false,
    courseId: null,
    to: null,
    contentType: null,
    confirmCompletion: 0,
    historical: false,
    priorCourseTitle: null,
    strings: {},
    title: null,
    selectedLanguage: {
      language: 'English',
      languageLong: 'English (United States)',
      languageKey: 'EN-US',
      languageId: 1033,
      rtl: false,
    },
    tabIndex: 0,
    ariaLabel: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
    }
    this.launch = this.launch.bind(this)
  }

  async launch() {
    const {
      launchCourse,
      launchable,
      isMobile,
      checkMobileSettings,
      windowOptions,
      courseId,
      to,
      contentType,
      confirmCompletion,
      historical,
      priorCourseTitle,
      selectedLanguage,
      strings,
      title,
      player,
      percipioLanguageID,
      complianceSurveyOrgID,
      complianceSurveyMainProductID,
      nasbaSurveyOrgID,
      nasbaSurveyMainProductID,
      hasVideo,
    } = this.props

    const savedLaunchParams = JSON.parse(persistence.get('launchParams'))

    let a11yEnabled = savedLaunchParams && savedLaunchParams.a11yEnabled ? true : false

    const launchParams = {
      launchable,
      checkMobileSettings,
      courseId,
      to,
      contentType,
      a11yEnabled,
      player,
      percipioLanguageID,
      complianceSurveyOrgID,
      complianceSurveyMainProductID,
      nasbaSurveyOrgID,
      nasbaSurveyMainProductID,
      hasVideo,
    }
    const modalParams = {
      confirmCompletion,
      contentType,
      courseId,
      historical,
      launchable,
      isMobile,
      checkMobileSettings,
      priorCourseTitle,
      selectedLanguage,
      strings,
      title,
    }
    await launchCourse(launchParams, modalParams)

    const isLaunchable = isMobile ? checkMobileSettings : launchable;

    if (isLaunchable) {
      if (player === 'ria' || player === 'certitude') {
        this.setState({
          redirect: true,
        })
      } else {
        const options = windowOptions
        const courseWindow = window.open(
          '',
          'courseWindow',
          options
        );
        console.log('launch course', launchParams, modalParams, courseWindow, player)
        await launchCourse(launchParams, modalParams, courseWindow, player)
      }
    }
  }

  render() {
    const { children } = this.props
    const { redirect } = this.state
    return redirect ? (
      <Redirect push to="/player" />
    ) : (
      <div className="card-root-body"
        tabIndex="-1"
        onClick={() => this.launch()}
        onKeyPress={(e) => (e.key === 'Enter' ? this.launch() : null)}
      >
        {children}
      </div>
    )
  }
}

export default LaunchLink

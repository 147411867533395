import socketClient from 'socket.io-client'
import jwt_decode from 'jwt-decode'
import persistence from '../../utils/persistence'
import validation from '../../utils/validation'
import { ACADEMY, BFFSOCKET, BFF } from '../../constants'

export const fetchLaunchKey = async params => {
  const response = await fetch(`${BFF}coursekey/${params.courseId}`, {
    headers: new Headers({
      Authorization: `Bearer ${params.jwt}`,
    }),
  })
  if (!validation.validate(response)) return null
  return response.json()
}

export const getLaunchUrl = async ({
  to,
  contentType,
  courseId,
  a11yEnabled,
  fetchLaunchKeyFn = fetchLaunchKey,
}) => {
  if (!(contentType === 'WBT' || contentType === 'LP')) {
    return to
  }
  const user = persistence.get('user')
  const jwt = JSON.parse(user).jwtToken
  const key = await fetchLaunchKeyFn({ courseId, jwt })
  const decodedJwt = jwt_decode(jwt)
  const usePendo = persistence.get("usePendo")
  const organizationId = decodedJwt.orgId
  const visitorId = decodedJwt.percUUID


  if (key === null) {
    return null
  }
  console.log("ACADEMY", ACADEMY, to)
  const baseUrl = ACADEMY.endsWith('/') ? ACADEMY.slice(0, ACADEMY.length - 1) : ACADEMY;
  const pathUrl = to.startsWith('/') ? to.slice(1, to.length - 1) : to;

  const launchUrl = new URL([baseUrl, pathUrl].join('/'));
  const params = launchUrl.searchParams;
  params.append('keyid', key.keyId)
  if (a11yEnabled) params.append('use508', '1')
  if (usePendo === "true") {
    params.append('percipioOrgId', `${organizationId}`)
    params.append('visitorId', `${visitorId}`)
  }
  params.append("cache", `${Date.now()}`)
  return launchUrl.toString()
}

export const getCourseUrl = async (organizationId, courseId) => {
  const user = persistence.get('user')
  const jwt = JSON.parse(user).jwtToken
  const response = await fetch(`${BFF}coursepath/${organizationId}/${courseId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'content-type': 'application/json',
    },
    method: 'GET',
  })
  return response.json()
}

export const confirmCourse = courseId => {
  const jwt = JSON.parse(persistence.get('user')).jwtToken
  const response = fetch(`${BFF}learner/postOtherCompletion`, {
    body: JSON.stringify({ courseId }),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'content-type': 'application/json',
    },
    method: 'POST',
  })
  if (!validation.validate(response)) return null
  return response
}

let socket = null
export const subscribe = (callback = () => { }) => {
  const jwt = JSON.parse(persistence.get('user'))
  socket = socketClient(`${BFFSOCKET}?token=${jwt.jwtToken}`, {
    transports: ['polling'],
  })
  socket.on('updateAssignments', () => {
    socket.disconnect()
    callback()
  })
  socket.emit('subscribe')
}

export const unsubscribe = () => {
  if (socket !== null) {
    socket.disconnect()
  }
}
